<template>
    <div>
    <b-card title="" border-variant="primary" class="text-left">
        <b-row
            v-for="(financing_document, index) in documents"
            :key="index">
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Tipus" label-for="type">
                    <v-select
                        v-model="financing_document.document_type_id"
                        :key="financing_document.id"
                        :options="documentsTypesList"
                        :reduce="(option) => option.id"
                        label="name"
                        @input="updateDocumentList"
                    />
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Document" label-for="date-document">
                    <b-form-file
                        v-model="financing_document.document_file"
                        placeholder="Tria un document o deixa'l anar aquí..."
                        drop-placeholder="Tria un document o deixa'l anar aquí..."
                        browse-text="Seleccionar"
                    />
                </b-form-group>
            </b-col>
            <b-col v-if="financing_document.document" lg="1" md="1" sm="1">
                <a :href="baseUrl+financing_document.document" target="_blank">
                    <b-button
                        variant="outline-success"
                        class="mt-2"
                    >
                        <feather-icon
                            icon="EyeIcon"
                        />
                    </b-button>
                </a>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Descripció" label-for="description">
                    <b-form-input v-model="financing_document.description" name="description"
                                  placeholder="Descripció"/>
                </b-form-group>
            </b-col>
            <b-col lg="2" md="12" sm="12">
                <b-form-group label="Data" label-for="birthday">
                    <flat-pickr
                        v-model="financing_document.date_update"
                        class="form-control"
                        placeholder="Data"
                        :config="{ enableTime: false, locale: CatalanLocale, dateFormat: 'd-m-Y'}"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="1 mt-2">
                <b-button
                    variant="outline-danger"
                    @click="removeItem(index, financing_document.id)"
                >
                    <feather-icon
                        icon="TrashIcon"
                    />
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" class="pb-2">
                <b-button
                    variant="primary"
                    @click="addNewRow"
                >
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-25"
                    />
                    <span>Afegir</span>

                </b-button>
            </b-col>
        </b-row>

    </b-card>
    <div class="justify-content-end">
        <div class="text-right">
            <b-button variant="primary" @click="updateDataDocuments">
                Actualitzar documents
            </b-button>
        </div>
    </div>
    </div>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BFormRadio,
    BFormCheckbox,
    BTab,
    BTabs,
    BFormFile,
    BIcon
} from 'bootstrap-vue'
import {Catalan as CatalanLocale} from 'flatpickr/dist/l10n/cat'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"
import {baseUrl} from "@/constants/app"


import ToastificationContent from "@core/components/toastification/ToastificationContent"
import Swal from "sweetalert2"

const {mapFields} = createHelpers({
    getterType: 'financing/getField',
    mutationType: 'financing/updateField',
})

export default {
    name: "FormDocumentsFinancing",
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BCard,
        BOverlay,
        BFormRadio,
        BFormCheckbox,
        BTab,
        BTabs,
        BFormFile,
        BIcon,

        vSelect,
        flatPickr,

    },
    data() {
        return {
            CatalanLocale,
            clients: [],
            documentsTypesList: [],
            baseUrl,
        }
    },
    created() {
        this.$root.$refs.getFinancing = this
    },
    computed: {
        ...mapState('financing', ['validation_financing']),
        ...mapFields({
            financing: 'financing',
            documents: 'financing.documents',
        }),
    },
    mounted() {
        this.getDocumentsByCategory(this.financing.financing_client_type)
    },
    methods: {
        getFinancing() {
            this.$store.dispatch('financing/getFinancing', {
                relations: ['client.clientPersonalData', 'financingDocuments', 'financingHolders'],
                id: this.financing.id,
            }) .then(response => {
                this.documents = response.financing_documents
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getDocumentsByCategory(category) {
            this.showLoading = true

            if(category === 'particular'){
                category = this.financing.autonomous == 1 ? 'self_employee' : category
            }

            this.$store.dispatch('financing/getDocumentsByCategory', {category: category})
            .then(response => {
                this.showLoading = false
                this.documentsTypesList = response
            })
            .catch(error => {
                console.log(error)
                this.showLoading = false
            })
        },
        addNewRow() {
            this.financing.documents.push({
                document_type_id: null,
                document_file: null,
                document: null,
                description: null,
                date_update: null,
                financing_id: this.financing.id
            })
        },
        removeItem(indexTax, documentId) {
            if (documentId) {
                Swal.fire({
                    title: "Esteu segur d'esborrar el document?",
                    text: "Un cop esborrat no ho podràs recuperar!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sí, esborra'l!",
                    cancelButtonText: "Cancel·lar",
                }).then(result => {
                    if (result.value) {
                        this.$store.dispatch('financing/deleteFinancingDocument', documentId)
                        .then(response => {
                            this.showLoading = false
                            this.showToast('Document eliminado correctament', 'CheckIcon', 'success')
                            this.financing.documents.splice(indexTax, 1)

                        })
                        .catch(error => {
                            this.showLoading = false
                            this.showToast('Error en eliminar document', 'CheckIcon', 'Alert')
                            console.log(error)
                        })
                    }
                })
            } else {
                this.financing.documents.splice(indexTax, 1)
            }

        },
        showToast(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                },
            })
        },
        updateDocumentList() {
            this.financing.documents.push({
                document_type_id: null,
                document_file: null,
                document: null,
                description: null,
                date_update: null,
                financing_id: this.financing.id

            })

            this.financing.documents.splice( this.financing.documents.length - 1, 1)
        },
        updateDataDocuments() {
            this.$store.dispatch('financing/updateFinancing', this.formatData())
            .then(response => {
                this.showToast('Documentació editada correctament!', 'CheckIcon', 'success')
                this.getFinancing()
            })
            .catch(error => {
                this.showToast('Error en editar la documentació', 'AlertOctagonIcon', 'danger')
            })
        },
        formatData() {
            let formData = new FormData()

            this.appendFormData(formData, this.financing, 'financing')
            this.appendFormData(formData, this.financing.documents, 'financing_documents')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (let i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>