<template>
    <div>
        <b-card class="p-1 p-lg-0">
            <b-tabs align="left">
                <b-tab active title="Informació general" lazy>
                    <b-overlay
                        variant="white"
                        :show="showLoading"
                        spinner-variant="primary"
                        blur="0"
                        opacity=".75"
                        rounded="sm"
                    >
                        <form-financing :disabled="edit"/>
                        <b-row align-h="start">
                            <b-col cols="5">
                                <b-button v-if="!edit" variant="primary" class="mt-2 mr-1" @click="sendData">
                                    Desar canvis
                                </b-button>
                                <b-button v-else variant="primary" class="mt-2 mr-1" @click="updateData">
                                    Actualitzar canvis
                                </b-button>
                                <b-button variant="outline-secondary" type="reset" class="mt-2" @click="cancel">
                                    Cancel·lar
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </b-tab>
                <b-tab v-if="financing.id" title="Dades personals" lazy>
                    <form-data-address-client :datacli="financing" :disabled="true"/>
                </b-tab>
                <b-tab v-if="financing.id" title="Documents" lazy>
                    <form-documents-financing/>
                </b-tab>
            </b-tabs>

        </b-card>

    </div>
</template>

<script>
import {BButton, BCard, BCol, BTab, BTabs, BOverlay, BRow} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import {FINANCING_LIST} from "@/router/web/constants"
import ToastificationContent from "@core/components/toastification/ToastificationContent"

import FormFinancing from "@/views/finance/financing/form/formFinancingView"
import FormDataAddressClient from "@/views/clients/form/formClientPersonalDataView"
import FormDocumentsFinancing from "@/views/finance/financing/form/components/formDocumentsView"

import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

const {mapFields} = createHelpers({
    getterType: 'financing/getField',
    mutationType: 'financing/updateField',
})

export default {
    name: "FinancingFormView",
    components: {
        BCol,
        BRow,
        BButton,
        BCard,
        BOverlay,
        BTab,
        BTabs,

        FormFinancing,
        FormDataAddressClient,
        FormDocumentsFinancing
    },
    data() {
        return {
            users: [],

            client: null,
            edit: false,
            showLoading: false,
            SpanishLocale,
        }
    },
    computed: {
        ...mapState('financing', ['validation_financing']),
        ...mapFields({
            financing: 'financing',
        }),
    },
    created() {
        this.$store.commit('financing/RESET_STATE')

        if (this.$route.params.id) {
            this.edit = true
            this.getFinancing()
        } else {
            this.edit = false
        }
    },
    methods: {
        getFinancing() {
            this.showLoading = true

            this.$store.dispatch('financing/getFinancing', {
                id: this.$route.params.id,
                relations: ['client', 'client.clientPersonalData', 'financingDocuments', 'financingHolders'],
            })
            .then(response => {
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        sendData() {
            this.$store.dispatch('financing/createFinancing', this.formatData())
            .then(response => {
                this.edit = true
                this.getFinancing()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Operació de finançament creada correctament!`,
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
            })
            .catch(error => {
                console.log(error)
            })
        },
        updateData() {
            this.$store.dispatch('financing/updateFinancing', this.formatData())
            .then(() => {
                this.showLoading = false
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Operació de finançament editada correctament!`,
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.getFinancing()
            })

            .catch(error => {
                console.log(error)
            })
        },
        cancel() {
            this.$router.push(FINANCING_LIST)
        },
        formatData() {
            var formData = new FormData()
            this.appendFormData(formData, this.financing, 'financing')
            return formData
        },
        appendFormData(formData, data, rootName) {
            let root = rootName || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.appendFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.appendFormData(formData, data[key], key)
                        } else {
                            this.appendFormData(formData, data[key], `${root}[${key}]`)
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                } else {
                    formData.append(root, '')
                }
            }
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>