<template>
    <div>
        <b-row>
            <b-col sm="6">
                <b-form-group label="Client" label-for="value">
                    <v-select
                        v-model="financing.client_id"
                        :options="clients"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disabled"
                    >
                        <template #no-options="{ }">
                            No s'han pogut carregar els clients
                        </template>
                        <template slot="option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                            {{ option.name }} {{ option.first_surname }} {{ option.second_surname }}
                        </template>
                    </v-select>
                    <small v-if="validation_financing.client_id" class="text-danger">
                        {{ validation_financing.client_id }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Tipus" label-for="value">
                    <v-select
                        v-model="financing.type"
                        :options="investmentTypes"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_financing.type" class="text-danger">
                        {{ validation_financing.type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Tipus client" label-for="value">
                    <v-select
                        v-model="financing.financing_client_type"
                        :options="financingTypeClient"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_financing.financing_client_type" class="text-danger">
                        {{ validation_financing.financing_client_type }}
                    </small>
                </b-form-group>
            </b-col>
<!--            <b-col sm="5">
                <b-form-group :label="titleName" label-for="account-surnames">
                    <b-form-input v-model="financing.name" placeholder="Nom"
                                  :class="validation_financing.name ? 'is-invalid': ''"/>
                    <small v-if="validation_financing.name" class="text-danger">
                        {{ validation_financing.name }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="5">
                <b-form-group label="Segon titular" label-for="account-username">
                    <b-form-input v-model="financing.second_holder_name" placeholder="Segon titular"
                                  :class="validation_financing.second_holder_name ? 'is-invalid': ''"/>
                    <small v-if="validation_financing.second_holder_name" class="text-danger">
                        {{ validation_financing.second_holder_name }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="DNI segon titular" label-for="account-username">
                    <b-form-input v-model="financing.second_holder_dni" placeholder="DNI segon titular"
                                  :class="validation_financing.second_holder_dni ? 'is-invalid': ''"/>
                    <small v-if="validation_financing.second_holder_dni" class="text-danger">
                        {{ validation_financing.second_holder_dni }}
                    </small>
                </b-form-group>
            </b-col>-->
            <b-col sm="4">
                <b-form-group label="Entitat" label-for="account-username">
                    <v-select
                        v-model="financing.financing_entity"
                        :options="investmentsFinancialEntities"
                        label="name"
                        :reduce="(option) => option.value"
                    />
                    <small v-if="validation_financing.financing_entity" class="text-danger">
                        {{ validation_financing.financing_entity }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Tipus finançament" label-for="account-username">
                    <b-form-input v-model="financing.financing_type" placeholder="Tipus finançament"
                                  :class="validation_financing.financing_type ? 'is-invalid': ''"/>
                    <small v-if="validation_financing.financing_type" class="text-danger">
                        {{ validation_financing.financing_type }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="Data d'entrada" label-for="account-surnames">
                    <flat-pickr
                        v-model="financing.entry_date"
                        class="form-control"
                        :class="validation_financing.entry_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data d'entrada"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_financing.entry_date" class="text-danger">
                        {{ validation_financing.entry_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="3" v-if="financing.type === 'renovation'">
                <b-form-group label="Data de venciment" label-for="account-surnames">
                    <flat-pickr
                        v-model="financing.expiration_date"
                        class="form-control"
                        :class="validation_financing.expiration_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data de Venciment"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_financing.expiration_date" class="text-danger">
                        {{ validation_financing.expiration_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="3" v-if="financing.type === 'new'">
                <b-form-group label="Data de renovació" label-for="account-surnames">
                    <flat-pickr
                        v-model="financing.renewal_date"
                        class="form-control"
                        :class="validation_financing.renewal_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data de renovació"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_financing.renewal_date" class="text-danger">
                        {{ validation_financing.renewal_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="3">
                <b-form-group label="Data signatura" label-for="account-surnames">
                    <flat-pickr
                        v-model="financing.signature_date"
                        class="form-control"
                        :class="validation_financing.signature_date ? 'is-invalid': ''"
                        name="date"
                        placeholder="Data signatura"
                        :config="{ enableTime: false, locale: SpanishLocale, dateFormat: 'd-m-Y'}"
                    />
                    <small v-if="validation_financing.signature_date" class="text-danger">
                        {{ validation_financing.signature_date }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Import" label-for="account-surnames">
                    <money class="form-control" v-model="financing.amount" :class="validation_financing.amount ? 'is-invalid': ''"/>
                    <small v-if="validation_financing.amount" class="text-danger">
                        {{ validation_financing.amount }}
                    </small>
                </b-form-group>
            </b-col>
            <b-col sm="2">
                <b-form-group label="Import signat" label-for="account-surnames">
                    <money class="form-control" v-model="financing.signed_amount" :class="validation_financing.signed_amount ? 'is-invalid': ''"/>
                    <small v-if="validation_financing.signed_amount" class="text-danger">
                        {{ validation_financing.signed_amount }}
                    </small>
                </b-form-group>
            </b-col>

            <b-col lg="3" md="12" sm="12">
                <b-form-group label="Estat" label-for="value">
                    <select v-model="financing.status" class="custom-select" :class="'background-' + financing.status">
                        <option v-for="status in investmentStatus"
                                :key="status.value"
                                :selected="status.value === financing.status"
                                :value="status.value"> {{ status.name }}
                        </option>
                    </select>
                    <small v-if="validation_financing.status" class="text-danger">
                        {{ validation_financing.status }}
                    </small>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="3" v-if="financing.financing_client_type === 'particular'">
                <b-form-group label="Autònom" label-for="agreement">
                    <div class="demo-inline-spacing">
                        <b-form-radio v-model="financing.autonomous" name="autonomous"
                                      value="1">
                            Sì
                        </b-form-radio>
                        <b-form-radio v-model="financing.autonomous" name="autonomous"
                                      value="0">
                            No
                        </b-form-radio>
                    </div>
                    <small v-if="validation_financing.autonomous" class="text-danger">
                        {{ validation_financing.autonomous }}</small>
                </b-form-group>
            </b-col>
        </b-row>
        <div v-if="this.$store.state.client.client.type == 'legal_person' && this.financing.financing_client_type == 'company'">
            <hr>
                <form-partners/>
            <hr>
        </div>
        <div v-if="this.financing.financing_client_type == 'particular'">
            <hr>
            <form-holders/>
            <hr>
        </div>
    </div>
</template>

<script>
import {BCol, BFormGroup, BFormInput, BRow, BFormRadio} from 'bootstrap-vue'
import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es'
import vSelect from "vue-select"
import flatPickr from 'vue-flatpickr-component'
import {createHelpers} from "vuex-map-fields"
import {mapState} from "vuex"

import {
    financingTypeClient,
    investmentsFinancialEntities,
    investmentStatus,
    investmentTypes,
} from "@/constants/finance"
import FormPartners from "@/views/clients/form/components/formPartners"
import FormHolders from "@/views/finance/financing/form/components/formHolders"

const {mapFields} = createHelpers({
    getterType: 'financing/getField',
    mutationType: 'financing/updateField',
})

export default {
    name: "FormFinancingView",
    components: {
        BCol,
        BRow,
        BFormGroup,
        BFormInput,
        BFormRadio,
        FormPartners,
        FormHolders,

        vSelect,
        flatPickr,
    },
    props: ['disabled'],
    data() {
        return {
            SpanishLocale,
            clients: [],
            investmentsFinancialEntities,
            investmentTypes,
            investmentStatus,

            financingTypeClient,
        }
    },
    computed: {
        ...mapState('financing', ['validation_financing']),
        ...mapState('client', ['validation_client']),
        ...mapFields({
            financing: 'financing',
        }),
        titleName() {
            return this.financing.financing_type === 'company' ? 'Nom Empresa' : 'Nom particular'
        }
    },
    watch: {
        'financing.client_id': function (client) {
            if (client) {
                this.getClientData(client)
            }
        }
    },
    created() {
        this.getClients()
    },
    methods: {
        getClients() {
            this.showLoading = true

            this.$store.dispatch('client/getAllClients', {
                id: this.$route.params.id,
                relations: ['responsible'],
            })
            .then(response => {
                this.clients = response
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
        getClientData(client_id) {
            this.showLoading = true
            this.$store.dispatch('client/getClient', {
                id: client_id,
                relations: ['clientPartners'],
            })
            .then(response => {
                this.clientData = this.$store.state.client.client
                console.log(response)
                this.showLoading = false
            })
            .catch(error => {
                this.showLoading = false
            })
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-flatpicker.scss';


.background-in_progress {
    color: #ffc107;
    border: 1px solid #ffc107;
}

.background-accepted {
    color: #28c76f;
    border: 1px solid #28c76f;
}

.background-cancelled {
    color: #82868b;
    border: 1px solid #82868b;
}

.background-denied {
    color: #ea5455;
    border: 1px solid #ea5455;
}

select option[value="in_progress"] {
    color: #ffc107 !important;
    background: rgba(255, 159, 67, 0.12);
}

select option[value="accepted"] {
    color: #28c76f !important;
    background: rgba(40, 199, 111, 0.12);
}

select option[value="cancelled"] {
    color: #82868b !important;
    background: rgba(130, 134, 139, 0.12);
}

select option[value="denied"] {
    color: #ea5455 !important;
    background: rgba(234, 84, 85, 0.12);
}
</style>